import axios from 'axios'

export function getAllRsb () {
  return axios.get('/maestros/rsb')
  .then(response => {
    return response.data
  })
}

export function updateRsb (id, rsb) {
  return axios.put('/maestros/rsb/' + id, rsb)
  .then(response => {
      return response.data
    })
}

export function addRsb (rsb) {
  return axios.post('/maestros/rsb', rsb)
  .then(response => {
    return response.data
  })
}

export function deleteRsb (id) {
  return axios.delete('/maestros/rsb/' + id)
  .then(response => {
      return response.data
    })
}

export function getLogsRsb (id) {
  return axios.get('/maestros/rsb/audit/' + id)
  .then(response => {
      return response.data
    })
}

export default {
  getAllRsb,
  updateRsb,
  addRsb,
  deleteRsb,
  getLogsRsb,
}
