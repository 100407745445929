import axios from 'axios'

export function getAllLocalidad () {
  return axios.get('/maestros/localidad')
  .then(response => {
    return response.data
  })
}

export function updateLocalidad (id, localidad) {
  return axios.put('/maestros/localidad/' + id, localidad)
  .then(response => {
      return response.data
    })
}

export function addLocalidad (localidad) {
  return axios.post('/maestros/localidad', localidad)
  .then(response => {
    return response.data
  })
}

export function deleteLocalidad (id) {
  return axios.delete('/maestros/localidad/' + id)
  .then(response => {
      return response.data
    })
}

export function getOneLocalidad (id) {
    return axios.get('/maestros/localidad/' + id)
    .then(response => {
        return response.data
      })
}

export function getLocalidadesByProvincia (id) {
  return axios.get('/maestros/localidad/provincia/' + id)
  .then(response => {
      return response.data
    })
}

export function getLogsLocalidad (id) {
  return axios.get('/maestros/localidad/audit/' + id)
  .then(response => {
      return response.data
    })
}

export default {
  getAllLocalidad,
  updateLocalidad,
  addLocalidad,
  deleteLocalidad,
  getOneLocalidad,
  getLocalidadesByProvincia,
  getLogsLocalidad,
}
