import axios from 'axios'

export function addSociedad (sociedad) {
  return axios.post('/sociedad',
  sociedad,
  {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  },
  ).then(response => {
    return response.data
  })
}

export function getAllSociedadesByCliente (cliente) {
  return axios.get('/sociedad/' + cliente)
  .then(response => {
    return response.data
  })
}

export function getSociedad (id) {
  return axios.get('/sociedad/' + id)
  .then(response => {
    return response.data
  })
}

export function updateSociedad (id, sociedad) {
  return axios.post('/sociedad/' + id,
    sociedad,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  ).then(response => {
      return response.data
    })
}

export function deleteSociedad (id) {
  return axios.delete('/sociedad/' + id)
  .then(response => {
      return response.data
    })
}

export function downloadEscritura (id) {
  return axios({
    method: 'get',
    url: '/sociedad/file/' + id,
    responseType: 'blob',
  })
  .then(response => {
      return response
    })
}

export default {
  addSociedad,
  getAllSociedadesByCliente,
  getSociedad,
  updateSociedad,
  deleteSociedad,
  downloadEscritura,
}
