import axios from 'axios'

export function getAllEc () {
  return axios.get('/maestros/ec')
  .then(response => {
    return response.data
  })
}

export function updateEc (id, ec) {
  return axios.put('/maestros/ec/' + id, ec)
  .then(response => {
      return response.data
    })
}

export function addEc (ec) {
  return axios.post('/maestros/ec', ec)
  .then(response => {
    return response.data
  })
}

export function deleteEc (id) {
  return axios.delete('/maestros/ec/' + id)
  .then(response => {
      return response.data
    })
}

export function getOneEc (id) {
    return axios.get('/maestros/ec/' + id)
    .then(response => {
        return response.data
      })
}

export function getLogsEc (id) {
  return axios.get('/maestros/ec/audit/' + id)
  .then(response => {
      return response.data
    })
}

export default {
  getAllEc,
  updateEc,
  addEc,
  deleteEc,
  getOneEc,
  getLogsEc,
}
