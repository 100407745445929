import axios from 'axios'

export function getAllForal () {
  return axios.get('/maestros/foral')
  .then(response => {
    return response.data
  })
}

export function updateForal (id, foral) {
  return axios.put('/maestros/foral/' + id, foral)
  .then(response => {
      return response.data
    })
}

export function addForal (foral) {
  return axios.post('/maestros/foral', foral)
  .then(response => {
    return response.data
  })
}

export function deleteForal (id) {
  return axios.delete('/maestros/foral/' + id)
  .then(response => {
      return response.data
    })
}

export function getOneForal (id) {
    return axios.get('/maestros/foral/' + id)
    .then(response => {
        return response.data
      })
}

export function getLogsForal (id) {
  return axios.get('/maestros/foral/audit/' + id)
  .then(response => {
      return response.data
    })
}

export default {
  getAllForal,
  updateForal,
  addForal,
  deleteForal,
  getOneForal,
  getLogsForal,
}
