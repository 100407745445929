import axios from 'axios'

export function getAllCargos () {
  return axios.get('/maestros/cargos')
  .then(response => {
    return response.data
  })
}

export function updateCargos (id, cargo) {
  return axios.put('/maestros/cargos/' + id, cargo)
  .then(response => {
      return response.data
    })
}

export function addCargos (cargo) {
  return axios.post('/maestros/cargos', cargo)
  .then(response => {
    return response.data
  })
}

export function deleteCargos (id) {
  return axios.delete('/maestros/cargos/' + id)
  .then(response => {
      return response.data
    })
}

export function getOneCargos (id) {
    return axios.get('/maestros/cargos/' + id)
    .then(response => {
        return response.data
      })
}

export function getLogsCargos (id) {
  return axios.get('/maestros/cargos/audit/' + id)
  .then(response => {
      return response.data
    })
}

export default {
  getAllCargos,
  updateCargos,
  addCargos,
  deleteCargos,
  getOneCargos,
  getLogsCargos,
}
